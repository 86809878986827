import {
  userEditableTags,
  editableCompanyTags,
} from "@/helpers/variables/tags.js";

const editableTags = { ...userEditableTags, ...editableCompanyTags };

export function getTextColorByBackgroundColor(bgColor) {
  const light = "white";
  const dark = "black";

  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? dark : light;
}

export function getDarkByBackgroundColor(backgroundColor) {
  var color = getTextColorByBackgroundColor(backgroundColor);
  return color === "black" ? false : true;
}

export function getTagsMissing(selectedUsers, html) {
  if (html) {
    const tagsKeys = Object.keys(editableTags);

    return selectedUsers.reduce((acc, user) => {
      const userInfo = Object.entries(user);

      let missingTags = [];

      userInfo.forEach(([key, value]) => {
        if (tagsKeys.includes(key)) {
          if (!value && html.includes(editableTags[key].label)) {
            missingTags.push(editableTags[key].keyName);
          }
        }
      });
      if (missingTags.length > 0) {
        acc[user.name] = missingTags;
      }
      return acc;
    }, {});
  }
  return {};
}
