import { links } from "./links";

export const icons = [
  {
    url: "https://drive.google.com/thumbnail?id=1Qgg41fb4WCpBbvhPULL8IGFbnxlN4cO7&sz=w1000",
    alt: "email",
    title: "email",
  },
  {
    url: links.twitterIcon,
    alt: "[*COMPANY_TWITTER_LOGO_LIGHT*]",
    title: "twitter",
  },
  {
    url: links.facebookIcon,
    alt: "[*COMPANY_FACEBOOK_LOGO_LIGHT*]",
    title: "facebook",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1Nmx2iem7lLN48LuFxkkq-JFGeMZEIVu6&sz=w1000",
    alt: "[*COMPANY_YOUTUBE_LOGO_LIGHT*]",
    title: "youtube",
  },
  {
    url: links.instagramIcon,
    alt: "[*COMPANY_INSTAGRAM_LOGO_LIGHT*]",
    title: "instagram",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1SXXsamelNzW5GB00HTEtyn5h5hVLSTza&sz=w1000",
    alt: "[*COMPANY_WHATSAPP_LOGO_LIGHT*]",
    title: "whatsapp",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1PZ4Yisuox3Xb2A3SxcD5gpQdAJVLyOW5&sz=w1000",
    alt: "web icon",
    title: "webiconoutlined",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1j8Be1p-76WSRuP6fU18_ykj7Ww6teSb1&sz=w1000",
    alt: "telephone",
    title: "telephone",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1vHyeZ4RPZmcM7cNJcuffuWZ1NqCzMsr-&sz=w1000",
    alt: "link icon",
    title: "link",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1vvW1VYWW2boipXLMF1Bg1SqdpO1qXk5k&sz=w1000",
    alt: "location icon",
    title: "location",
  },

  {
    url: "https://drive.google.com/thumbnail?id=170tsSX9FbyK1RCn4EsCTfgRquyrx6YOF&sz=w1000",
    alt: "[*CALENDAR_SCHEDULES_LINK_LOGO_LIGHT*]",
    title: "calendar-account-outline",
  },

  {
    url: links.computerScreenIcon,
    alt: "computer screen",
    title: "computer",
  },

  {
    url: links.linkedInIcon,
    alt: "[*COMPANY_LINKEDIN_LOGO_LIGHT*]",
    title: "linkedin",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1F-Yx3H3kAc3-I6QKMjXsxenNRPsnHufW&sz=w1000",
    alt: "TikTok",
    title: "tiktok",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1s_Ujg9w_Qq3_w06qTKCnJBMod3YK4Xle&sz=w1000",
    alt: "[*COMPANY_TWITTER_LOGO_LIGHT*]",
    title: "X",
  },
];

export const darkIcons = [
  {
    url: "https://drive.google.com/thumbnail?id=13D3KCHDUHkDHm_0M4F_RimxMRe4AeA-y&sz=w1000",
    alt: "email",
    title: "email",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1nw6uIXerfUEP2hSVQWAqd1wauTSA48A2&sz=w1000",
    alt: "[*COMPANY_TWITTER_LOGO_DARK*]",
    title: "twitter",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1skhRVVz3kyT9-uaOOahm1v2FfCC1dYN8&sz=w1000",
    alt: "[*COMPANY_FACEBOOK_LOGO_DARK*]",
    title: "facebook",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1abnEBQlhznSFJU2KnwTxc65RXK9rVsaW&sz=w1000",
    alt: "[*COMPANY_YOUTUBE_LOGO_DARK*]",
    title: "youtube",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1RbB3HfrxMw7XNEpTqS15CO55uQOMsIQd&sz=w1000",
    alt: "[*COMPANY_INSTAGRAM_LOGO_DARK*]",
    title: "instagram",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1t7KFgXJGO4ZMz0tYk5UW3vcvl64MqJ2_&sz=w1000",
    alt: "[*COMPANY_WHATSAPP_LOGO_DARK*]",
    title: "whatsapp",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1tcBRxx7oBDQFj3JwBHOvQV6q8AMpMUdw&sz=w1000",
    alt: "web icon",
    title: "webiconoutlined",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1H9RI2wMLEhvNe4PB0P4m9m7Zv0IDWMcP&sz=w1000",
    alt: "telephone",
    title: "telephone",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1YC2RfPIkNEAQFBPHY4lt6yJfiD4jMCyn&sz=w1000",
    alt: "link icon",
    title: "link",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1JNtvVFY4Jj994MaIGtPangKFQ1RLlQ0q&sz=w1000",
    alt: "location icon",
    title: "location",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1HxWi9F97Aw6gzk5ko_DeBFmJHLxKmX1x&sz=w1000",
    alt: "[*CALENDAR_SCHEDULES_LINK_LOGO_DARK*]",
    title: "calendar-account-outline",
  },

  {
    url: "https://drive.google.com/thumbnail?id=16axWSArU7s7fNbtYtV5xyY3oHOJ-xF30&sz=w1000",
    alt: "computer screen",
    title: "computer",
  },

  {
    url: "https://drive.google.com/thumbnail?id=1zph6YGuVFi5GKUe8B9u13ZdJjk8VOGsl&sz=w1000",
    alt: "[*COMPANY_LINKEDIN_LOGO_DARK*]",
    title: "linkedin",
  },
  {
    url: "https://drive.google.com/thumbnail?id=1z9ayRMgFKLfl6sbyF17Zsfd7uYwpfgAn&sz=w1000",
    alt: "TikTok",
    title: "tiktok",
  },
  {
    url: "https://drive.google.com/thumbnail?id=15SW49BNMTzg4uywX1Z-k-rPvSk3Nb0S7&sz=w1000",
    alt: "[*COMPANY_TWITTER_LOGO_DARK*]",
    title: "X",
  },
];

export const allIcons = [
  {
    id: 1,
    key: "ios-label",
    name: "iOS",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=13EpBjUCZbFhsHgjsr840K3F3yz9_Y-Lc&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1FTtJt3S7fsSCyMYF-6nEVNR9vDQzO8pd&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1pb17sTC-DTJfEgIgVkgN6EtIY-S4emgD&sz=w1000",
    },
  },
  {
    id: 2,
    key: "rocket",
    name: "Foguete",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=13dB99eo8A6K33f0BqN1yOdBiT1nXW-S4&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=17GBFz4yt5rCmO_03hc4KaVfwuBwg_d-e&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1iR6gvDYMJXIctyLz91SEr0TA6cLTHsGf&sz=w1000",
    },
  },
  {
    id: 3,
    key: "facebook",
    name: "Facebook",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=14CMVoRlfRUJKAjCJqMki3RDNbqDhwNiz&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1JnoJK2Bcf55Gp_r2jJPSQTS1liBrPruW&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=149rimcxe0kj1adc1i5-Sxjum3qeRcRDT&sz=w1000",
    },
  },
  {
    id: 4,
    key: "chat",
    name: "Chat",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=15qkah5M-hkuc83EuGpfF1frko8akKqE_&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=13MKeOH7Zh7-333w5bUySbN4x8u8Mq3eN&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1kGYbTNavTa1gukP4xWltJ3o2PvQ1rz95&sz=w1000",
    },
  },
  {
    id: 5,
    key: "double-chevron-right",
    name: "Seta dupla simplificada à direita",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=15umCIGVJal1JeeVyM5FHsn4EzvHnLwFE&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1XatIWWe5iaSksv3FBBO0w30EZBoJhocP&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1tIPqHFJIMpQ8FZcWFU4j8zY1xY59jubi&sz=w1000",
    },
  },
  {
    id: 6,
    key: "tooltip-check",
    name: "Diálogo de confirmação",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=16g0dakOLYGtdOEFASTQxqJHOSmH_gdw8&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1iDMDdttkEVVNBMjfzpU_NJjvgWGI80pz&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1Z6u1kyVy64KYzUaYDdRFPwztxkjN-c1Y&sz=w1000",
    },
  },
  {
    id: 7,
    key: "signature",
    name: "Assinatura",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=16ybY8LWsUYsqMZwjOaC-AymaHjTKjnG3&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1v-1jOst-OdSyuGI4mul1fpq64nvUC6k2&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1-4mAJbWXIkSZdPljMGmIzYFFOA5YD3jT&sz=w1000",
    },
  },
  {
    id: 8,
    key: "settings",
    name: "Configurações",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1775K9ato1hivbseEhE95j1NgZwqwZQ6C&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1SGaFSc6lsdLqRoqhcFqL4fktb_oJUFPt&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1XQBOmiRFB8mB3ylsmclknDeKWomvajOi&sz=w1000",
    },
  },
  {
    id: 9,
    key: "spotify",
    name: "Spotify",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=19OJqMEWZJlyyoDbnZ2QqUi5CqVa0_Rxj&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=10wzMnMvuz_5GHw9IDMUq2lFG-D2nlpLG&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=12g-XqJSkhWi4Yyh3u-8OLSFE7wO4swjV&sz=w1000",
    },
  },
  {
    id: 10,
    key: "map",
    name: "Mapa",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=19TLmj3kwP7ROKFow-E4MW8I6h6sMPHoQ&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1DHo1BdGfC_7wZ1QDd8cWHKF5-zDB3HQz&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1dUdKOTnkbpQArAisxqaTqSqmChxUkxqR&sz=w1000",
    },
  },
  {
    id: 11,
    key: "arrows",
    name: "Setas",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1DcYqSOZGcnfJCCv8nbBvaDhWFiMDKnUv&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1LdgeoHbFQ1GWtzqpD3RoC0OAIx4e28FF&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1gifrnmvAraiIn-hcZhHlY3u0rwu8kGtx&sz=w1000",
    },
  },
  {
    id: 12,
    key: "google-classroom",
    name: "Google Classroom",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1E3aodu-6EvAbO-oU-89ZWa-Sb5-8gHY3&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1gYBzJypEQUROm_6M3cnIKXQjbysoyLO0&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1pSzBOOZZNKz7qhFrtI_NXScS7om_9__5&sz=w1000",
    },
  },
  {
    id: 13,
    key: "reddit",
    name: "Reddit",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1FdC6D0kuNVOzisscxm8TyKhg7cdNPD3P&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1aNojMfS6LqgTzTaQm-ccBY4eUAGKbtyH&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1gMXJGc7ILfrNUR8CLnpKJgMk0mBcj89S&sz=w1000",
    },
  },
  {
    id: 14,
    key: "user-laptop",
    name: "Computador do usuário",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1Jg6R2SzaIDj6I5C4He9ROrfyj_zgOSPM&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=16axWSArU7s7fNbtYtV5xyY3oHOJ-xF30&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=110jLdCPs_6BZvmCR1_aUlNRoxb2NMCtv&sz=w1000",
    },
  },
  {
    id: 15,
    key: "cart",
    name: "Carrinho de compras",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1JrYfXCZ0SxmldnDl_CzwWyFC5H4Pv-FY&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1teKBvp6LsHaLmJ1qY7F7GmX1mDWZurMY&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=15ErUultSzk5J7WF1zK1rdzN3VAKAgRUo&sz=w1000",
    },
  },
  {
    id: 16,
    key: "security-check",
    name: "Segurança",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1MmoYYsS747QDPkYB45X_eDc9DfNqCugS&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1EA7mZsc0_7viGQcVLarzbIAlMAA5RL__&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=16INx90_1KHYm2eB2hPmBFlh2bLmEH4fL&sz=w1000",
    },
  },
  {
    id: 17,
    key: "soundcloud",
    name: "Soundcloud",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1N3kaTV8CDPBqq5D_LjqwDxANW6pK4X8D&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1R3gFXrlUGIoprSktFj0h1unm3FRFNTly&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1NO2OlnVKSjJhPVdxgbjmZoYut7welNYZ&sz=w1000",
    },
  },
  {
    id: 18,
    key: "battery-charge",
    name: "Bateria",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1N_Q_FyNQQwuFnhCPgase8GuSxzMPH_nr&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1AG54BBUY8jDXIY1r16GLAKG_j6uXXAzT&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=11ju_LF_YK2DQ_U6EWLAUKU7eHziqX_zJ&sz=w1000",
    },
  },
  {
    id: 19,
    key: "chevron-right",
    name: "Seta simplificada à direita",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1OI9hCvLpvGwwzwlRuWfsR-DcAH3HKQwE&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1IudzfuyUoNIFteu34H3AuoPfyBZVrOf9&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1NUSuTN-iBTz4ps_zdMyE7BTo7ghVwIjr&sz=w1000",
    },
  },
  {
    id: 20,
    key: "movie-open",
    name: "Ripa de cinema aberta",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1OL4YLoyygOBcZsNdjeOMVBXAYISKlIbo&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1v3Q_PSLblcQ3kZZNioOmYj8dM5FLAecG&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1Dqk_Thfo6WERRDkuG7GlclL7MYwPuA4u&sz=w1000",
    },
  },
  {
    id: 21,
    key: "cat",
    name: "Gato",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1OSCG2xjVT5wn-dG4Es4wzEtXKNDNZ46h&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1TNUU_9Y_VqdCNgfFUXEBs5fRfxThFrgN&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1pKT1gIuNp3OvidmDZDCEQv1ywPGtB-vd&sz=w1000",
    },
  },
  {
    id: 22,
    key: "arrow-right",
    name: "Seta à direita",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1PbG7of5zJm_OeH4PcvebIYEZ6hiqXjaz&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1uCSlEtooW4JlhMSONKLbgaG4J0GLqvj5&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1lzLumBFxBBFmb2TlorRPEeliR4SV0nOK&sz=w1000",
    },
  },
  {
    id: 23,
    key: "smile-face",
    name: "Emoji sorrindos",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1QZ--yAZBtUIclON2uQk7H3U8baHFtn6M&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1gzv0Wd4-4V-2nuGZ6SLBezQ9tiv6Syh5&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=12kMXrRZ9ahUisCxyqr1vZQNNa5RWUtJg&sz=w1000",
    },
  },
  {
    id: 24,
    key: "mensseger",
    name: "Mensseger",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1RVH3iEu0S_dq6uAgi5tNSFGYWTBnXnIb&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1SP0sGAP2cItOPTeW5IF47p2EyJGb6fgZ&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=10ApprIuyR1B3VVXe8UFbLsSOfFc50xeS&sz=w1000",
    },
  },
  {
    id: 25,
    key: "video-conference",
    name: "Vídeo conferência",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1SAH6xWnWZXbfqmr8AIt0EBP9s82cX7bG&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1LC6J1ZxVoIGillfVNivVLkpIdQDomQGN&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=12t8R_fk7Xkdx_0aOYkP1UKZ7ha948G8l&sz=w1000",
    },
  },
  {
    id: 26,
    key: "sticker-smile",
    name: "Sticker sorriso",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1SAH6xWnWZXbfqmr8AIt0EBP9s82cX7bG&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1YA1qJktnA1pH0cKq65OWUH0hi1CzBJne&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1AFPGIurn-ru8-v7sulNpTvM2NCNs5ErQ&sz=w1000",
    },
  },
  {
    id: 27,
    key: "linkedin",
    name: "Linkedin",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1SjMm8LlYOanRHqRUFoWO3gFi9sFqfXdS&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1tgDwUFmUy1wVXqGXO5vxRRbJhv52enrz&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1GrbgTUDH98Lu2ZMGpxilWk789DfKBcDc&sz=w1000",
    },
  },
  {
    id: 28,
    key: "link",
    name: "Link",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1U9Xyjb7MyiGP_zo-QG9JtK9ok4Gw7FAg&sz=w1000 ",
      black:
        "https://drive.google.com/thumbnail?id=1vEWiNSXVg1ccuTUJHGMLZPjPrmBhyIWi&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1-EDxf_CXDtpoatsZ2hM4ohX3-gDQRbUE&sz=w1000",
    },
  },
  {
    id: 29,
    key: "twitch",
    name: "Twitch",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1Yj5z3ls-AELlWzXcN6AhVVDb46pc7awK&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1OgcxlH0S3orWJ5twApl4YDvgdezSSZAN&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1derObRQo8XZ6PXojHIYY4SVfoq8wuPHg&sz=w1000",
    },
  },
  {
    id: 30,
    key: "gift",
    name: "Presente",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1_Hq1tZ6KMEcut-LT442kxb2yQqmCPvBd&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=138kioAlLIF6W9Q0qH1AtTC4zSGLaNMuI&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=18oinb5ZkQ3WD7XArgL-Mp7IzPINjK54Z&sz=w1000",
    },
  },
  {
    id: 31,
    key: "open-new-tab",
    name: "Abrir em outra guia",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1_RvWoGd-VpU5wRHpriKsUHZDfl8rsX8E&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1qEhcdv2NxEjSw0hYNquMP1oxfsQh7Vvs&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1Qc6nqCMLL3YO4g7wbV6T4Tjs-w7lLa9_&sz=w1000",
    },
  },
  {
    id: 32,
    key: "shopping-bag",
    name: "Bolsa de compras",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1akiU8WUspS5aVXVP6mdC3eQ-0afLrXaY&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1JQTjCd7s9TRgA1bdVO6b8ijOsWPErr89&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1JHsLUeLYJf0lh1fk9UTMfv6jGJuA9_cV&sz=w1000",
    },
  },
  {
    id: 33,
    key: "youtube",
    name: "Youtube",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1b40hmV-EflgRN2RgPAzMSnrplJKV0HSC&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1LU-Yvp-PiUA7n-G3vf4UbaXfQ73FayqJ&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=13WQIKfltVaz8FMM94VDyqLKDcYfDg7-M&sz=w1000",
    },
  },
  {
    id: 34,
    key: "car",
    name: "Carro",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1bdlUD_hrOqpLN-S9DTFtp2mnsIBBtj7L&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1N6VrKTURe-8z8apgToUOSPPM1vy36KTm&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=14yrnMZQQKToevdpDRXtmip0-APQzBs6d&sz=w1000",
    },
  },
  {
    id: 35,
    key: "credit-card-check",
    name: "Cartão de crédito aceito",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1eavQvlQUtfZhEgIpHVYnT_fVW-LYnybC&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1BCjuYURhWWt8Eh8IkbZl6emTuvjRKx56&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=170XkgYg0L9BAr7fJFfwhUAjOJCwZcXnh&sz=w1000",
    },
  },
  {
    id: 36,
    key: "kiss-face",
    name: "Emoji rosto mandando beijo",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1f5tLYqPSzNed9mTl-5gwTmny_pPG1KjU&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1zwubbnli0CHXktbT1XOSxl3PMpnPGU_P&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1xRFIHuBjGQWcCm69cnFZHZQbHwOgQ1jI&sz=w1000",
    },
  },
  {
    id: 37,
    key: "video-conference-2",
    name: "Vídeo conferência - versão 2",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1gl3ZOzOx0HailZZ0jqJ6lHcrepuC7qWE&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1efvgGsJLIiCVdBX3O-7hKrwJBtgyMGed&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1l5jzsD6FwTGcPfyEXdeIDiJbaOXKGNWT&sz=w1000",
    },
  },
  {
    id: 38,
    key: "dog",
    name: "Cachorro",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1huWDdShgiPl3U54esbjcjK8h-xGa81GS&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1Ef2paBvlwjXrEPpp1AY8X4rMuk61Nu_D&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1cYzKEP7jV39qMwKHnEhFnHnjYF9XNcgF&sz=w1000",
    },
  },
  {
    id: 39,
    key: "music-album",
    name: "Álbum de música",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1i2uZiozZbeIFzhKrOOg0OOqcdYVFIXsb&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1EIIAc-Qk9AQmnLv6dKiouRS0ICr7TYKi&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1DI0PhmGVkGoNFFEfexmYBtoxULhZcEf-&sz=w1000",
    },
  },
  {
    id: 40,
    key: "movie",
    name: "Ripa de cinema fechada",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1itqveMNbCTWg5tAePh6ZGzaLP6YDAX2l&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1suFdeRqxTK3INu9uzl7hVVA97lOlleJl&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1X_Y0LieHSYysw8QSLvocsXLhoGy9uCvs&sz=w1000",
    },
  },
  {
    id: 41,
    key: "gift-card",
    name: "Cartão-presente",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1j0DYtKAt5Ur-ZKBwgWAIVgKt4-l1dFLM&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=138kioAlLIF6W9Q0qH1AtTC4zSGLaNMuI&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1Z1L-MfjDu39fa_YKr0IHNnJV4a17xj-i&sz=w1000",
    },
  },
  {
    id: 42,
    key: "map-marker",
    name: "Marcador de mapa",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1jS-lSfwWOU4EMjDeJrj_3OLCxQGeKFql&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1ONmyqvkgxFkYPogzwM4ArLL1ufEEmGOv&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=17c70gX42yFy1V8PuD_ZyGVGdQQ5ZBp9e&sz=w1000",
    },
  },
  {
    id: 43,
    key: "dialog-warning",
    name: "Diálogo de aviso",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1kUUnMWwT9AQIYMy2HmI7sBSDl-wOCbHQ&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1sVmZEjXrPxnJJ27ngZXUHCFviu4KbXTM&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1rdolLRK1brCPmp0JI19swDh1JJrDxDkM&sz=w1000",
    },
  },
  {
    id: 44,
    key: "arrow-right-circle",
    name: "Seta à direita circular",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1kyTT4YBqwawbje6mQKUiPC6G2TuSVhN3&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1Zf0iaxTW1D-7noYJZqkpNBFtp2pjfh_X&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1TafJtdMDyfuUfE9KwmDhkBg0h5hU1ozH&sz=w1000",
    },
  },
  {
    id: 45,
    key: "warning",
    name: "Aviso",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1mIa4DvyGAsBObjLVfuLeHXx2YF2JwuHG&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1EzF9ya7cuH9SuSN5EU7rHxKUdu0mzIph&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1O8GNCxoo2RECVr2ywklUM6iy4-1IVO4_&sz=w1000",
    },
  },
  {
    id: 46,
    key: "youtube-tv",
    name: "Youtube TV",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1msNsEmY-lL438n7Vet2NDpTB6Dw1zEHk&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1Rn_auhBBI7Ko6uUuJSgsW4Rwer-kTlLz&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1b_oEmiQxmBX4ajWy5wFYHopD-ONuit8R&sz=w1000",
    },
  },
  {
    id: 47,
    key: "fast-money",
    name: "Dinheiro rápido",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1oKhjzEBXTNOgMVhvPyMsa8dsjsIYdJfP&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1vbQs1qSxjvUqWb2EcUBNN2rUJmHBivCX&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1csQBUZfnvD_qNem1vHbkBr6UfDkh-t9b&sz=w1000",
    },
  },
  {
    id: 48,
    key: "drive-folder",
    name: "Pasta do Google Drive",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1q4-C-Eku7h1jEdEsthDibGsxCyOar6z1&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1yEE160ARaTSBpdKjnFbWLkK5N3wF9idm&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1F_Cj9OA2V7tREOeuVRThpgUnTo6jKikc&sz=w1000",
    },
  },
  {
    id: 49,
    key: "calendar-clock",
    name: "Agendamento",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1x2xGflDrCAfYeZHAP6itvxoRnDmJZ6Hb&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1b8OmRtcrIjDcf72n-TPw7u8B0elN2l1s&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=11O25iLZN5ibbNIPEqV_SQwKCvAsQvH8g&sz=w1000",
    },
  },
  {
    id: 50,
    key: "twitter",
    name: "Twitter",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=13y_2PbGGWJkveFo1Wc_2SfzTT-gtAX_d&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1ZNhzeTP6EzP_MOfrkC01e_ZR442KNZpX&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1ppl9N9NjwXQWUGxiQl3z7Qaa7xjnjbGe&sz=w1000",
    },
  },
  {
    id: 51,
    key: "google-play-store",
    name: "Google Play Store",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=19SC0GQdbyvtIxfYq_kYwwJDWzWBUfBKV&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1bOjNQo-N9aIhKo92KoFXHbPWmkpuUrF8&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1xO7e4rwPj_9si8KuG7dtXNRfglMv8b_g&sz=w1000",
    },
  },
  {
    id: 52,
    key: "android",
    name: "Android",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1A0poWaMUx_P8uTPjaLR22yCiKemkpAun&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1DUalonighd8cmW3UstHO4ckvxS0c8aE4&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=13e62XrYsvLwGUncmalL5hh_rlhoxIiSK&sz=w1000",
    },
  },
  {
    id: 53,
    key: "apple",
    name: "Apple",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1ByElZhhZDrm_tcDClfFiQXOlwL19aQVu&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1OK2OWbkvq-SoLFksOfVOMnrjvg_gFq1O&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1febvlOSvOKYL2VD4ZiT6lZqhktm2kEGr&sz=w1000",
    },
  },
  {
    id: 54,
    key: "google",
    name: "Google",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1_HzE8SUdT7ngdYZcX4yvE14TicwkFZj7&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1qQ_949QYSzqkXjKIVXrDuoLj6Pq5ziQ0&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=14CSpQOuftXbLiFIrQ5dA-6xfLUqByRCw&sz=w1000",
    },
  },
  {
    id: 55,
    key: "whatsapp",
    name: "Whatsapp",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1_blQ1NdhyQ33cEGHFyZ3WQjse2Cd9ZDL&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1pyaXkmEfmRUXVXtNFud584Pfe3nAdwP9&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1380w16ngJY7dZeD1tapyAqGpktkr86ug&sz=w1000",
    },
  },
  {
    id: 58,
    key: "vimeo",
    name: "Vimeo",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1pLbNnPfgzGa_VFnqz0W6bYZs9zmPSfOp&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1Z9Qa-0LIW1QCO3RLP9ymz0FurZh_X6Un&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1GYm9P5LRrF6amG8YdrYLyNtjVu6qVgIc&sz=w1000",
    },
  },
  {
    id: 59,
    key: "instagram",
    name: "Instagram",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1q_me8vq831g6n0KmbIjaKMfYlGIvJWtN&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1f0T46eqOY8yj3wmH3eeP6jG4zC0_LBM8&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1-34FleYdul1Lj0BLSn_qTW8BkFy_PxFU&sz=w1000",
    },
  },
  {
    id: 60,
    key: "drive",
    name: "Google Drive",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1ta9Hu4GcBH3whStcz9n6P5VNI8LSQ9Lc&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1fZ2Ho2lq33mutF7-mTmexGJDEuC_3KGr&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1kC3CFg6jw9m4Al1ugoj67m8YFVsjr4Py&sz=w1000",
    },
  },
  {
    id: 61,
    key: "microsoft",
    name: "Microsoft",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1xhjYLE2xETA-o6LUR0v13So6ZJk27mre&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1VOoYRvRym7CvHgKYPs9Na_nm9uKO8r9a&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1n3l5sdA8GmaYbhRo8sM_0IfGfZ79ZTdC&sz=w1000",
    },
  },
  {
    id: 62,
    key: "phone",
    name: "Telefone",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=17qbhKSelwR7FRM_LmHjW0JZbxP96VnSc&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1K0z-e9NLTfc1JJ1B1E1lHxOfkdo_P-jo&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1y4zCy0_3RlhnHvmLkpFKreyOtfb0Rd4j&sz=w1000",
    },
  },
  {
    id: 63,
    key: "user-calendar",
    name: "Calendário do usuário",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1KHA-wRAN0lnrJ1pakSXvaiY5KcnDbEpt&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1HxWi9F97Aw6gzk5ko_DeBFmJHLxKmX1x&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1tr1XbfnTRLECekcDVGxjc1um8_6-tqml&sz=w1000",
    },
  },
  {
    id: 64,
    key: "heart-cart",
    name: "Favoritos no carrinho de compras",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1KTVPEi-8hgBxpoCFa3RYqV6k14xs-SmY&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=15PMAxoBC2n4_VNOsavRXgG5I_9-dAJu_&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=18krTnZ1NYU4-shpph0OazCpqZmZPWaU5&sz=w1000",
    },
  },
  {
    id: 65,
    key: "sparkle",
    name: "Brilho",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1LGPHXuVM_cIdxFR35kbOPIKHGh49QCvC&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=18-ZxxQVifJeEb_M8RyM722WK_P6ai3Ij&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1Hkbegni25ffEWgweocOopDzmShZT9jLY&sz=w1000",
    },
  },
  {
    id: 66,
    name: "Web",
    key: "web",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1Q15QIoF5rYpQVD93WYlirztMuRISVZgF&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1t_Y3RjvI0GGaPD2zceURE1G3PyEH1eF7&sz=w1000",
      white: links.webWhiteIcon,
    },
  },
  {
    id: 67,
    key: "heart",
    name: "Coração",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1ZJge4uIOx5jgu_h0FVubc0sdY3eov8lP&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1aODbOXOK3wwb5195LqIhdmpavn4BJVVY&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1ukQ-g-oG315OkPsSNwtZCykeMuKYb545&sz=w1000",
    },
  },
  {
    id: 68,
    key: "hand-offer-heart",
    name: "Doação",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1kbw--APYCGQ8habg5qksPV64Dw6G0fkJ&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=139ct1WB27wnUC86TFr3DAHkNoOtAap6o&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1GoHXPHV44X49gIBHMl9QMTRUmQOaU0f1&sz=w1000",
    },
  },
  {
    id: 69,
    key: "like",
    name: "Like",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1l644qVidww15WI0a8-_SZN1YH12mDGRB&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1-NMUnriC47F_pcOERjRynMSbG3JFh5yt&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1pabT2HKLhMH7Kkd-wNgIK2nw7mKk27iE&sz=w1000",
    },
  },
  {
    id: 70,
    key: "dislike",
    name: "Dislike",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1wGa5HiJOsM91ys9Y7V84J43c8TbNwQai&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1xIaBP4aUk0HEU_jIE6g65hhoaGGDVkr1&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1p_m9wQyuoT5K1gq74J3wDdG9190EZEQt&sz=w1000",
    },
  },
  {
    id: 71,
    key: "birthday",
    name: "Bolo de aniversário",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1nDkxlV_S71yjhP_yK_DB2cwpOXqEFWR3&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=13CfxlsIy1z5puRpPyCj1N6rxeKNUAGct&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1gBxX4bO93vlNF3l3RSFtGJirRTXz93un&sz=w1000",
    },
  },
  {
    id: 72,
    key: "download",
    name: "Download",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1I9WBl3jOBaTCmQdSaYjxea8Q7bVULiI9&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1-xGTckZUq1BYIXN1sjFyMVCnDI-WtMxi&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1jY1r22BFEGHvzfjckdI3A41r2VyiwFOZ&sz=w1000",
    },
  },
  {
    id: 73,
    key: "skype",
    name: "Skype",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1V_bkODZYYFntqat_tOEUpiBrCwBIwQGq&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=15Tn_s9_ku8ggZdRhDb7owBhuUYhC0Jhl&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=12g-XqJSkhWi4Yyh3u-8OLSFE7wO4swjV&sz=w1000",
    },
  },
  {
    id: 74,
    key: "tiktok",
    name: "TikTok",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1PSeiBf-sBR-Hgh96C-lmTMBLAz8Lyri4&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=1z9ayRMgFKLfl6sbyF17Zsfd7uYwpfgAn&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1F-Yx3H3kAc3-I6QKMjXsxenNRPsnHufW&sz=w1000",
    },
  },
  {
    id: 75,
    key: "X",
    name: "X",
    url: {
      light_grey:
        "https://drive.google.com/thumbnail?id=1OJb-wCNdl9Nx9fFLPLPISaQDySVIM5oh&sz=w1000",
      black:
        "https://drive.google.com/thumbnail?id=15SW49BNMTzg4uywX1Z-k-rPvSk3Nb0S7&sz=w1000",
      white:
        "https://drive.google.com/thumbnail?id=1s_Ujg9w_Qq3_w06qTKCnJBMod3YK4Xle&sz=w1000",
    },
  },
];
