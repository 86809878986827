var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('v-row',{staticClass:"mt-4 mx-0"},_vm._l((_vm.displayIcons),function(icon,index){return _c('v-col',{key:index,staticClass:"pointer",attrs:{"cols":"2"},on:{"click":function($event){return _vm.setIcon(icon)}}},[_c('div',{style:(`
          width:28px;
          height:28px;
          background: ${_vm.color};
          display: flex;
          justify-content: center;
          align-items: center;
        `)},[_c('img',{staticClass:"my-1",attrs:{"src":icon.url,"width":_vm.defaultSize,"alt":icon.alt,"title":icon.alt}})])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }