<template>
  <div>
    <v-card
      tile
      flat
      class="overflow-y-auto pa-1"
      width="100%"
      :height="iconPickerHeight"
    >
      <v-row v-if="multiple" class="ma-0 pa-0">
        <v-col v-for="(icon, i) in icons" :key="i">
          <v-list-item link class="justify-center rounded-lg py-2">
            <img
              @click="selectedIcon = icon.key"
              class="my-1"
              :src="icon.url"
              :width="iconSize"
              :alt="icon.key"
              :title="icon.name"
            />
          </v-list-item>
        </v-col>
      </v-row>
      <v-radio-group
        v-else
        v-model="selectedIcon"
        row
        class="pa-0 ma-0 align-center"
      >
        <v-radio
          hide-details
          :value="null"
          :label="defaultLabel"
          class="mx-2 mb-3 pt-2"
        />
        <v-radio
          v-for="(icon, i) in icons"
          :key="i"
          hide-details
          :value="icon.key"
          class="mx-2 mb-3"
        >
          <template #label>
            <img
              class="my-1"
              :src="icon.url"
              :width="iconSize"
              :alt="icon.key"
              :title="icon.name"
            />
          </template>
        </v-radio>
      </v-radio-group>
    </v-card>
  </div>
</template>

<script>
import { allIcons } from "@/helpers/variables/icons";
import { hasChanges } from "@/helpers/services/utils";

export default {
  name: "IconPicker",

  props: {
    color: { type: String, default: "black" },
    defaultLabel: { type: String, default: "Sem ícone" },
    justArrows: { type: Boolean, default: false },
    selected: { type: String, default: "" },
    multiple: { type: Boolean, default: false },
    iconSize: { type: [String, Number], default: 30 },
    fullHeight: { type: Boolean, default: false },
  },

  data() {
    return {
      selectedIcon: null,
      allIcons,
    };
  },

  computed: {
    currentIcons() {
      return allIcons
        .reduce((acc, item) => {
          const { key, url, name } = item;
          acc.push({
            key: key,
            url: url.black,
            name: name,
          });
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
    },

    icons() {
      if (this.justArrows) {
        return this.currentIcons.filter(
          (item) => item.key.includes("right") || item.key.includes("tab")
        );
      }
      return this.currentIcons;
    },

    iconPickerHeight() {
      if (this.fullHeight) {
        return "100%";
      } else {
        return this.justArrows ? "auto" : 140;
      }
    },
  },

  watch: {
    selectedIcon() {
      this.$emit("update", this.selectedIcon);
    },

    selected() {
      if (hasChanges(this.selected, this.selectedIcon)) {
        if (this.selected) {
          this.selectedIcon = this.icons.find(
            (icon) => icon.key === this.selected
          );
        } else {
          this.selectedIcon = this.selected;
        }
      }
    },
  },
};
</script>
