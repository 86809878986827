<template>
  <v-card-actions class="pa-0">
    <v-btn-toggle v-model="borderStyle" multiple class="mr-4">
      <v-tooltip top v-for="(border, i) in buttonBorder" :key="i">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :value="border.value">
            <v-icon v-text="border.icon" />
          </v-btn>
        </template>
        <span>{{ border.name }}</span>
      </v-tooltip>
    </v-btn-toggle>
    <v-radio-group v-model="borderStyle" class="mr-4">
      <v-radio label="Todas" :value="['bordered']"></v-radio>
    </v-radio-group>
    <v-radio-group v-model="borderStyle">
      <v-radio label="Nenhuma" :value="[]"></v-radio>
    </v-radio-group>
  </v-card-actions>
</template>
<script>
import { buttonBorder } from "@/helpers/variables/editorOptionsDefault";
import { hasChanges } from "@/helpers/services/utils";
export default {
  name: "BorderStylePicker",

  props: {
    selected: { type: Array, default: () => [] },
  },

  data() {
    return {
      borderStyle: [],
      buttonBorder,
    };
  },

  watch: {
    selected() {
      if (hasChanges(this.borderStyle, this.selected)) {
        this.borderStyle = this.selected;
      }
    },

    borderStyle() {
      if (hasChanges(this.borderStyle, this.selected)) {
        this.emit();
      }
    },
  },

  methods: {
    emit() {
      const fullBordered =
        this.borderStyle.includes("border-left") &&
        this.borderStyle.includes("border-right") &&
        this.borderStyle.includes("border-top") &&
        this.borderStyle.includes("border-bottom");

      if (fullBordered) {
        this.borderStyle = ["bordered"];
      }

      if (
        this.borderStyle.length > 1 &&
        this.borderStyle.includes("bordered")
      ) {
        const index = this.borderStyle.indexOf("bordered");
        this.borderStyle.splice(index, 1);
      }

      this.$emit("update", this.borderStyle);
    },
  },

  beforeMount() {
    this.borderStyle = this.selected;
  },
};
</script>
