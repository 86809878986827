<template>
  <v-dialog v-model="menu" hide-overlay width="500">
    <template v-slot:activator="{ on, attrs }" class="orange">
      <label v-if="label" class="caption font-weight-medium">{{ label }}</label>
      <v-text-field
        :value="newColor"
        :background-color="newColor"
        :dark="getDarkByBackgroundColor(newColor)"
        :class="`outlined`"
        solo
        flat
        dense
        elevation="0"
        readonly
        v-bind="attrs"
        v-on="on"
        persistent-placeholder
        hide-details
      >
        <template v-slot:append>
          <slot name="custom-append" />
        </template>
      </v-text-field>
    </template>

    <v-card width="500">
      <v-app-bar color="primary" flat dark>
        <v-card-title>Selecionar cor</v-card-title>
      </v-app-bar>

      <v-card-text class="pt-5">
        <v-color-picker
          v-model="newColor"
          show-swatches
          hide-mode-switch
          width="500"
          mode="hexa"
          flat
        ></v-color-picker>
      </v-card-text>
      <v-footer>
        <v-btn
          block
          color="secondary lighten-1"
          text
          large
          @click="newColor = resetColor"
          class="text-none text-body-1 font-weight-medium text-none mx-auto"
        >
          <v-icon v-text="'mdi-history'" left size="20" />
          Restaurar cor original
        </v-btn>
        <v-btn
          block
          color="primary white--text"
          elevation="0"
          large
          @click="menu = false"
          class="mt-2 mb-2 text-none text-body-1 font-weight-medium text-none mx-auto"
        >
          Confirmar
        </v-btn>
      </v-footer>
    </v-card>
  </v-dialog>
</template>
<script>
import { getDarkByBackgroundColor } from "@/helpers/services/sign";
import { hasChanges } from "@/helpers/services/utils";

export default {
  name: "SelectColor",

  props: {
    label: { type: String, default: "Selecionar cor" },
    color: { type: String, default: "#000000" },
    resetColor: { type: String, default: "#000000" },
  },

  data() {
    return {
      newColor: this.color,
      menu: false,
    };
  },

  watch: {
    newColor() {
      if (this.newColor) {
        this.$emit("setNewColor", this.newColor);
      }
    },

    color() {
      if (hasChanges(this.color, this.newColor)) {
        this.newColor = this.color;
      }
    },
  },

  computed: {
    editColor: {
      get() {
        return this.newColor;
      },
      set(new_value) {
        this.newColor = new_value;
      },
    },
  },

  methods: {
    getDarkByBackgroundColor,
  },
};
</script>
