var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{class:`
      text-weight-regular
      ${_vm.medium ? 'text-weight-medium' : ''}
      ${_vm.bold ? 'text-weight-bold' : ''}
      ${_vm.styles}
      ${_vm.colsStyles}
    `,attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.info)?_c('TooltipIcon',{attrs:{"color":"secondary lighten-3","icon":'mdi-information-slab-circle-outline',"label":_vm.info}}):_vm._e()],1),_c('v-col',{class:`${_vm.colsStyles}`,attrs:{"cols":"8"}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }