<template>
  <v-autocomplete
    v-model="localSelected"
    :search-input.sync="searchText"
    :filter="searchUser"
    :disabled="disabled || loading"
    :background-color="!filled ? backgroundColor : ''"
    :items="allUsers"
    :item-value="value"
    :flat="flat"
    :color="color"
    :multiple="multiple"
    :filled="filled"
    :outlined="outlined"
    :solo="solo"
    :label="label"
    :append-icon="appendIcon"
    :return-object="returnObject"
    :class="`remove-icon-animation ${fieldClass}`"
    :placeholder="placeholder"
    :chips="multiple"
    hide-details
    clearable
  >
    <!-- ITENS SELECIONADOS (se for multiple) -->
    <template v-slot:selection="{ item, index, on, attrs, selected }">
      <div v-if="multiple">
        <v-chip
          v-if="index < 5"
          close
          close-icon="ph-bold ph-x"
          color="accent"
          outlined
          class="font-weight-medium"
          :disabled="disabled || loading"
          v-bind="attrs"
          :input-value="selected"
          @click:close="remove(index)"
        >
          <v-avatar left>
            <img :src="item.photo || '@/assets/user-default.png'" />
          </v-avatar>

          {{ item.name }}

          <span v-if="!item.is_gmail_enabled" class="px-1">
            <TooltipIcon
              icon="mdi-email-off-outline"
              color="error"
              :label="$t('common.emailServiceIsDisabled')"
            />
          </span>
          <span v-else-if="!item.is_editable" class="px-1">
            <TooltipIcon
              icon="ph-bold ph-lock"
              color="error"
              :label="$t('common.userRestrictDescription')"
            />
          </span>
          <span
            v-if="
              item.is_gmail_enabled &&
              showMissingTagsCount &&
              getUserTagsMissingNumber(item) > 0
            "
            class="px-1"
          >
            <TooltipIcon
              icon="ph-bold ph-warning"
              color="error"
              :label="`Informações do modelo que estão vazias no usuário ${
                item.name
              }:
                <b>${getUserTagsMissing(item).join(', ')}</b>`"
            />
          </span>
        </v-chip>

        <span
          v-if="
            index === 4 && canMinimizeOthersSelected && othersSelectedNumber > 0
          "
          class="secondary--text text-caption text-no-wrap py-2"
        >
          (+{{ othersSelectedNumber }} {{ othersSelectedLabel }})
        </span>
      </div>

      <!-- ITEM SELECIONADO (se não for multiple) -->
      <v-list-item v-else v-on="on" v-bind="attrs">
        <v-list-item-action v-if="!hideImage" class="mr-4">
          <v-badge
            v-if="!item.is_editable"
            bottom
            icon="ph-bold ph-lock"
            overlap
            class="mr-4"
            color="secondary"
          >
            <v-avatar left>
              <img :src="item.photo || '@/assets/user-default.png'" />
            </v-avatar>
          </v-badge>

          <v-avatar left v-else>
            <img :src="item.photo || '@/assets/user-default.png'" />
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="!hideEmail">
            {{ item.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-if="showEmailSettings" #prepend-item>
      <v-card-text>
        Usuários com o ícone
        <v-icon v-text="'ph-bold ph-lock'" small class="pr-1 pb-1" /> precisarão
        confirmar suas assinaturas antes das mesmas serem aplicadas.
      </v-card-text>
    </template>

    <!-- ITEM NA LISTA -->
    <template #item="{ item, on, attrs }">
      <v-list-item
        v-on="on"
        v-bind="attrs"
        :class="`${itemClass}`"
        active-class="lightSign amber--text text--accent-4 mb-1"
      >
        <v-list-item-action v-if="multiple" class="mr-4">
          <v-checkbox v-model="attrs.inputValue" />
        </v-list-item-action>
        <v-list-item-action v-if="!hideImage" class="mr-4">
          <v-badge
            v-if="!item.is_editable"
            bottom
            icon="ph-bold ph-lock"
            overlap
            class="mr-4"
            color="secondary"
          >
            <v-avatar left>
              <img :src="item.photo || '@/assets/user-default.png'" />
            </v-avatar>
          </v-badge>

          <v-avatar left v-else>
            <img :src="item.photo || '@/assets/user-default.png'" />
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="!hideEmail">
            {{ item.email }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="!item.is_gmail_enabled">
          <v-tooltip bottom max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                pill
                color="error"
                class="font-weight-bold px-4"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-text="'mdi-email-off-outline'" small />
              </v-chip>
            </template>
            <span>{{ $t("common.emailServiceIsDisabled") }}</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-action v-else-if="!item.is_editable">
          <v-tooltip bottom max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                pill
                color="error"
                class="font-weight-bold px-4"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-text="'ph-bold ph-lock'" small />
              </v-chip>
            </template>
            <span>{{ $t("common.userRestrictDescription") }}</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-action
          v-if="
            item.is_gmail_enabled &&
            showMissingTagsCount &&
            getUserTagsMissingNumber(item) > 0
          "
        >
          <v-tooltip bottom max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                pill
                color="error"
                class="font-weight-bold px-4"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left v-text="'ph-bold ph-warning'" small />
                {{ getUserTagsMissingNumber(item) }}
              </v-chip>
            </template>
            <span>
              Informações do modelo que estão vazias no usuário {{ item.name }}:
              <b>{{ getUserTagsMissing(item).join(", ") }}</b>
            </span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </template>

    <!-- TODO: implementar isso aqui depois -->
    <!-- <template #append-item>
      <v-card-actions v-if="multiple" class="append py-4 border-top background">
        <v-btn
          small
          color="accent"
          class="text-none text-caption text-sm-body-2 font-weight-medium px-4"
        >
          Selecionar todos
        </v-btn>

        <v-btn
          small
          color="accent"
          outlined
          class="text-none text-caption text-sm-body-2 font-weight-medium px-4"
        >
          Mostrar usuários sem Gmail habilitado
        </v-btn>

        <v-spacer />

        <v-btn
          small
          color="secondary"
          text
          class="text-none text-caption text-sm-body-2 font-weight-medium"
        >
          Limpar seleção
        </v-btn>
      </v-card-actions>
    </template> -->
  </v-autocomplete>
</template>
<script>
import { mapGetters } from "vuex";
import { getTagsMissing } from "@/helpers/services/sign";
import { sortByName } from "@/helpers/services/utils";
import TooltipIcon from "@/components/base/TooltipIcon.vue";

export default {
  name: "UsersPicker",
  props: {
    autocomplete: { type: Boolean, default: false },
    appendIcon: { type: String, default: "ph-bold ph-caret-down" },
    backgroundColor: { type: String, default: "" },
    color: { type: String, default: "primary" },
    disabled: { type: Boolean, default: false },
    fieldClass: { type: String, default: "" },
    filled: { type: Boolean, default: false },
    filterByValidEmails: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    hideEmail: { type: Boolean, default: false },
    hideImage: { type: Boolean, default: false },
    itemClass: { type: String, default: "" },
    label: { type: String, default: "Selecionar usuário" },
    loading: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    returnObject: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
    selected: { type: [Array, String, Object, Boolean], default: "" },
    showEmailSettings: { type: Boolean, default: false },
    showMissingTagsCount: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    value: { type: String, default: "email" },
    templateNewHtml: { type: String, default: "" },
  },
  data() {
    return {
      localSelected: [],
      searchText: "",
    };
  },
  computed: {
    ...mapGetters(["users", "selectedTemplateNewHtml"]),
    allUsers() {
      return sortByName(this.users);
    },
    canMinimizeOthersSelected() {
      return this.selectedNumber > 4;
    },
    hasUsersSelected() {
      return this.selectedNumber > 0;
    },
    othersSelectedLabel() {
      return this.othersSelectedNumber === 1 ? "outro" : "outros";
    },
    othersSelectedNumber() {
      return this.selectedNumber - 5;
    },
    selectedNumber() {
      return this.localSelected.length;
    },
  },
  watch: {
    localSelected() {
      this.$emit("update", this.localSelected);
    },
    selected() {
      this.reset();
    },
  },
  methods: {
    isSelected(user_to_find) {
      if (typeof this.localSelected === "object") {
        return !!this.localSelected.find(
          (user) => user[this.value] === user_to_find[this.value]
        );
      }
    },
    getUserTagsMissingNumber(user) {
      const missingtags = this.getUserTagsMissing(user);
      return missingtags.length;
    },
    getUserTagsMissing(user) {
      const missingtags = getTagsMissing(
        [user],
        this.selectedTemplateNewHtml || this.templateNewHtml
      );
      if (Object.keys(missingtags).length > 0) {
        return Object.values(missingtags)[0];
      }
      return [];
    },
    searchUser(item, queryText) {
      const textOne = item.email.toLowerCase().trim();
      const textTwo = item.name.toLowerCase().trim();
      const searchText = queryText.toLowerCase().trim();

      return textTwo.includes(searchText) || textOne.includes(searchText);
    },

    remove(index) {
      if (index >= 0) this.localSelected.splice(index, 1);
    },

    reset() {
      this.searchText = "";
      this.localSelected = this.selected;
    },
  },
  beforeMount() {
    this.reset();
  },
  components: { TooltipIcon },
};
</script>
<style>
.append {
  position: sticky;
  bottom: 0;
}
</style>
