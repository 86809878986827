<template>
  <v-row class="ma-0 pa-0" align="center">
    <v-col
      cols="4"
      :class="`
        text-weight-regular
        ${medium ? 'text-weight-medium' : ''}
        ${bold ? 'text-weight-bold' : ''}
        ${styles}
        ${colsStyles}
      `"
    >
      {{ label }}
      <TooltipIcon
        v-if="info"
        color="secondary lighten-3"
        :icon="'mdi-information-slab-circle-outline'"
        :label="info"
      />
    </v-col>
    <v-col cols="8" :class="`${colsStyles}`">
      <slot />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "RowLabel",

  props: {
    bold: { type: Boolean, default: false },
    info: { type: String, required: "" },
    medium: { type: Boolean, default: false },
    label: { type: String, required: true },
    styles: { type: String, default: "" },
    colsStyles: { type: String, default: "" },
  },
};
</script>
