<template>
  <div>
    <v-combobox
      v-model="link"
      :items="linkTags"
      item-text="text"
      item-key="key"
      :label="label"
      :color="primaryColor"
      :solo="solo"
      :flat="solo && flat"
      :filled="filled"
      :placeholder="placeholder"
      :background-color="backgroundColor"
      :hide-details="hideDetails && validation"
      :error="!validation"
      :success="validation"
      :error-messages="
        !validation ? 'Por favor, digite um link ou uma TAG válida' : ''
      "
      append-icon=""
      hide-selected
      clearable
      :return-object="false"
    >
      <template v-slot:append>
        <TooltipIcon
          :label="validation ? 'Link ou TAG válido' : 'Link ou TAG inválidos'"
          :color="validation ? 'success' : 'error'"
          :icon="validation ? 'mdi-check-circle' : 'mdi-alert-circle'"
          size="26"
        />
      </template>

      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.value }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.text }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-combobox>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  editableCompanyTags,
  userEditableTags,
} from "@/helpers/variables/tags.js";

export default {
  name: "LinkInput",

  props: {
    appliedLink: { type: String, default: "" },
    filled: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    label: { type: String, default: "" },
    placeholder: { type: String, default: "Digite um link ou escolha uma TAG" },
    solo: { type: Boolean, default: false },
    backgroundColor: { type: String, default: "" },
    hideDetails: { type: Boolean, default: false },
  },

  data() {
    return {
      link: "",
      editableCompanyTags,
      userEditableTags,
    };
  },

  computed: {
    ...mapGetters(["primaryColor"]),

    currentLink: {
      get() {
        return this.link;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.link = val;
        }, 600);
      },
    },

    validation() {
      const tag = new RegExp("[\\[\\*\\]]", "g");
      const validationTest = /^(ftp|http|https):\/\/[^ "]+$/;
      return validationTest.test(this.link) || tag.test(this.link);
    },

    companyTags() {
      return Object.values(this.editableCompanyTags);
    },

    userTags() {
      return Object.values(this.userEditableTags);
    },

    linkTags() {
      const tags = [...this.companyTags, ...this.userTags];

      const urlTags = tags
        .filter((tag) => tag.type === "url")
        .filter(
          (tag) =>
            !tag.label.includes("photo") &&
            !tag.label.includes("BANNER_URL") &&
            !tag.label.includes("LOGO_URL")
        )
        .map((item) => {
          return {
            value: item.label,
            text: item.keyName,
          };
        });

      const header = [
        {
          header: "Informações disponíveis",
        },
      ];

      return [...header, ...urlTags];
    },
  },

  watch: {
    validation() {
      this.$emit("validation", this.validation);
    },

    appliedLink() {
      this.link = this.appliedLink;
    },

    link() {
      this.$emit("update", this.currentLink);
    },
  },

  beforeMount() {
    this.link = this.appliedLink;
  },
};
</script>
