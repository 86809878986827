<template>
  <div class="">
    <v-row class="mt-4 mx-0">
      <v-col
        cols="2"
        v-for="(icon, index) in displayIcons"
        :key="index"
        class="pointer"
        @click="setIcon(icon)"
      >
        <div
          :style="`
            width:28px;
            height:28px;
            background: ${color};
            display: flex;
            justify-content: center;
            align-items: center;
          `"
        >
          <img
            class="my-1"
            :src="icon.url"
            :width="defaultSize"
            :alt="icon.alt"
            :title="icon.alt"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { icons, darkIcons } from "@/helpers/variables/icons";
import { getDarkByBackgroundColor } from "@/helpers/services/sign";

export default {
  name: "icons",
  props: {
    color: { type: String, default: "#000000" },
  },
  components: {},
  data: () => ({
    icons,
    defaultSize: "22",
    darkIcons,
  }),

  computed: {
    displayIcons() {
      return !getDarkByBackgroundColor(this.color)
        ? this.darkIcons
        : this.icons;
    },
  },

  methods: {
    getDarkByBackgroundColor,

    setIcon(icon) {
      this.$emit("action", icon);
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer !important;
}
</style>
