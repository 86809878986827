<template>
  <v-dialog
    v-model="showHide"
    :width="width"
    scrollable
    transition="scroll-x-reverse-transition"
  >
    <v-card>
      <v-toolbar color="primary" tile flat>
        <v-btn icon @click="showHide = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="font-weight-medium">
          Adicionar botão personalizado
        </v-toolbar-title>
      </v-toolbar>

      <v-card
        outlined
        color="grey lighten-4"
        class="pa-8 d-flex justify-center align-center mx-6 mt-4 font-weight-bold"
        style="font-weight: bold !important"
        height="120"
      >
        <div v-html="previewButtonHtml" />
      </v-card>

      <v-tabs v-model="currentTab" centered class="mt-5">
        <v-tab
          v-for="(tab, i) in tabs"
          :key="i"
          :class="`text-none text-body-1 font-weight-medium ${
            i === 0 && !button.link ? 'error--text' : 'secondary--text'
          }`"
        >
          <v-icon
            left
            v-text="tab.icon"
            :color="i === 0 && !button.link ? 'error' : 'secondary'"
          />
          {{ tab.name }}
          <v-tooltip v-if="i === 0 && !button.link" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-text="'mdi-alert-circle'"
                size="20"
                color="error"
                right
              />
            </template>
            <span>URL não preenchida</span>
          </v-tooltip>
        </v-tab>
      </v-tabs>

      <v-card tile flat max-height="450" class="overflow-y-auto">
        <v-tabs-items v-model="currentTab">
          <!-- ESTILOS GERAIS -->
          <v-tab-item>
            <v-card flat tile>
              <v-card-text class="px-3">
                <!-- LINK DO BOTÃO -->
                <RowLabel label="Link do botão" medium>
                  <LinkInput
                    v-model="button.link"
                    solo
                    flat
                    hide-details
                    :applied-link="button.link"
                    @update="button.link = $event"
                    @validation="linkIsValid = $event"
                    background-color="grey lighten-3"
                  />
                </RowLabel>

                <!-- TEXTO DO BOTÃO -->
                <RowLabel label="Texto do botão" medium>
                  <v-text-field
                    v-model="button.text"
                    solo
                    flat
                    hide-details
                    placeholder="Digite o texto que irá aparecer no botão"
                    background-color="grey lighten-4"
                    clearable
                  />
                </RowLabel>

                <!-- TAMANHO DO TEXTO -->
                <RowLabel label="Tamanho do texto" class="my-3" medium>
                  <v-slider
                    v-model="button.font_size"
                    max="30"
                    min="10"
                    track-color="lightSign"
                    thumb-color="primary"
                    thumb-label
                    ticks
                    hide-details
                    color="primary"
                  />
                </RowLabel>

                <!-- FORMATO DO BOTÃO -->
                <RowLabel label="Formato do botão" class="mb-4" medium>
                  <v-item-group v-model="button.format" hide-details>
                    <v-row class="ma-0 pa-0">
                      <v-col
                        v-for="(buttonFormat, i) in buttonFormats"
                        :key="i"
                        cols="4"
                        class="ml-0 pl-0 py-0"
                      >
                        <v-item
                          v-slot="{ active, toggle }"
                          :value="buttonFormat.value"
                        >
                          <v-card
                            :color="active ? 'success' : 'grey lighten-1'"
                            :class="`d-flex align-center justify-center ${buttonFormat.class} my-1`"
                            dark
                            width="100%"
                            height="40"
                            outlined
                            @click="toggle"
                          >
                            <v-scroll-y-transition>
                              <v-icon
                                v-if="active"
                                v-text="'mdi-check-circle'"
                                size="28"
                                class="mx-auto"
                              />
                            </v-scroll-y-transition>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                </RowLabel>

                <!-- ESTILO DO BOTÃO -->
                <RowLabel label="Tipo de botão" medium>
                  <v-btn-toggle v-model="button.button_style">
                    <v-tooltip
                      top
                      v-for="(buttonStyle, i) in buttonStyles"
                      :key="i"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :value="buttonStyle.value"
                        >
                          <v-icon v-text="buttonStyle.icon" />
                        </v-btn>
                      </template>
                      <span>{{ buttonStyle.name }}</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </RowLabel>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <!-- BORDA DO BOTÃO -->
                <RowLabel label="Borda do botão" medium>
                  <BorderPicker
                    v-model="button.borders"
                    :selected="button.borders"
                    @update="button.borders = $event"
                  />
                </RowLabel>

                <!-- TAMANHO DA BORDA -->
                <RowLabel label="Tamanho da borda" class="my-2" medium>
                  <v-slider
                    v-model="button.border_size"
                    max="20"
                    min="0"
                    track-color="lightSign"
                    thumb-color="primary"
                    thumb-label
                    ticks
                    hide-details
                    color="primary"
                  />
                </RowLabel>

                <!-- TAMANHO DA BORDA -->
                <RowLabel label="Estilo da borda" medium>
                  <v-radio-group
                    v-model="button.border_style"
                    class="pa-0 ma-0"
                  >
                    <v-radio
                      v-for="(borderStyle, n) in borderStyles"
                      :key="n"
                      :label="borderStyle.name"
                      :value="borderStyle.value"
                    />
                  </v-radio-group>
                </RowLabel>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- ÍCONES -->
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <!-- ÍCONE PRONTOS -->
                <RowLabel label="Ícones" medium>
                  <IconPicker
                    :selected="selectedIcon"
                    @update="selectedIcon = $event"
                  />
                </RowLabel>

                <!-- ÍCONE PERSONALIZADO -->
                <RowLabel label="Ícone personalizado" medium>
                  <v-text-field
                    v-model="customIcon"
                    solo
                    flat
                    hide-details
                    placeholder="Insira o link do ícone desejado"
                    background-color="grey lighten-4"
                  />
                </RowLabel>

                <!--  ALINHAMENTO DO ÍCONE -->
                <RowLabel label="Alinhamento do ícone" class="mt-3" medium>
                  <v-btn-toggle
                    v-model="button.icon_align"
                    class="py-0"
                    hide-details
                  >
                    <v-tooltip
                      top
                      v-for="(buttonStyle, i) in buttonIconAlignments"
                      :key="i"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :value="buttonStyle.value"
                        >
                          <v-icon v-text="buttonStyle.icon" />
                        </v-btn>
                      </template>
                      <span>{{ buttonStyle.name }}</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </RowLabel>

                <!-- ADICIONAR SETA -->
                <RowLabel label="Seta à direita" medium>
                  <IconPicker
                    class="mt-2"
                    :default-label="'Nenhuma seta'"
                    :selected="button.right_arrow"
                    @update="button.right_arrow = $event"
                    just-arrows
                  />
                </RowLabel>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- CORES -->
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <!-- COR DO TEXTO -->
                <RowLabel label="Cor do texto" medium>
                  <SelectColor
                    label=""
                    v-model="button.text_color"
                    :color="button.text_color"
                    :reset-color="'#FFFFFF'"
                    @setNewColor="button.text_color = $event"
                  >
                    <template #custom-append>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="button.text_color = hexColorButtonIcon"
                          >
                            <v-icon v-text="'mdi-emoticon-excited-outline'" />
                          </v-btn>
                        </template>
                        <span>Usar mesma cor do ícone</span>
                      </v-tooltip>
                    </template>
                  </SelectColor>
                </RowLabel>

                <RowLabel label="" cols-styles="pa-0">
                  <v-btn
                    text
                    small
                    class="text-none text-body-2 font-weight-medium ml-4 px-0"
                    color="grey darken-1"
                    @click="invertBackgroundAndTextColor"
                  >
                    <v-icon v-text="'mdi-arrow-up-down'" left />
                    Inverter cor do texto e botão
                  </v-btn>
                </RowLabel>

                <!-- COR DO BOTÃO -->
                <RowLabel label="Cor do botão" medium>
                  <SelectColor
                    label=""
                    v-model="button.background_color"
                    :color="button.background_color"
                    :reset-color="'#CCCCCC'"
                    @setNewColor="button.background_color = $event"
                  />
                </RowLabel>

                <!-- COR DO ÍCONE -->
                <RowLabel label="Cor do ícone" medium>
                  <v-item-group v-model="button.icon_color" hide-details>
                    <v-row class="ma-0 pa-0">
                      <v-col
                        v-for="(iconColor, i) in iconColors"
                        :key="i"
                        cols="4"
                        :class="`ml-0`"
                      >
                        <v-item
                          v-slot="{ active, toggle }"
                          :value="iconColor.value"
                        >
                          <v-card
                            :color="iconColor.class"
                            style="border: 2px solid #ccc !important"
                            :class="`d-flex align-center justify-center`"
                            dark
                            height="35"
                            outlined
                            @click="toggle"
                          >
                            <v-scroll-y-transition>
                              <v-icon
                                v-if="active"
                                v-text="'mdi-check-circle'"
                                size="28"
                                :color="iconColor.text_class"
                                class="mx-auto"
                              />
                            </v-scroll-y-transition>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                </RowLabel>

                <!-- COR DA BORDA -->
                <RowLabel label="Cor da borda" medium>
                  <SelectColor
                    label=""
                    v-model="button.border_color"
                    :color="button.border_color"
                    :reset-color="'#000000'"
                    @setNewColor="button.border_color = $event"
                  >
                    <template #custom-append>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="button.border_color = hexColorButtonIcon"
                          >
                            <v-icon v-text="'mdi-emoticon-excited-outline'" />
                          </v-btn>
                        </template>
                        <span>Usar mesma cor do ícone</span>
                      </v-tooltip>
                    </template>
                  </SelectColor>
                </RowLabel>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- AVANÇADO -->
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <!-- TAMANHO DO BOTÃO -->
                <RowLabel label="Tamanho do botão" medium>
                  <v-btn-toggle v-model="button.size" mandatory>
                    <v-btn
                      v-for="(size, i) in buttonSize"
                      :key="i"
                      :value="size.value"
                      class="text-body-1 font-weight-medium"
                    >
                      {{ size.name }}
                    </v-btn>
                  </v-btn-toggle>
                </RowLabel>

                <!-- ALINHAMENTO DO TEXTO -->
                <RowLabel label="Formato das letras" medium>
                  <v-btn-toggle v-model="button.letter_transform" mandatory>
                    <v-tooltip
                      top
                      v-for="(button, i) in letterFormats"
                      :key="i"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" :value="button.value">
                          <v-icon v-text="button.icon" size="26" />
                        </v-btn>
                      </template>
                      <span>{{ button.tip }}</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </RowLabel>

                <!-- DECORAÇÃO DO TEXTO -->
                <RowLabel label="Decoração do texto" medium>
                  <v-card-actions class="pa-0 ma-0">
                    <v-btn-toggle v-model="button.text_decoration">
                      <v-tooltip
                        top
                        v-for="(button, i) in textDecorations"
                        :key="i"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" :value="button.value">
                            <v-icon v-text="button.icon" />
                          </v-btn>
                        </template>
                        <span>{{ button.name }}</span>
                      </v-tooltip>
                    </v-btn-toggle>
                    <v-radio-group v-model="button.text_decoration">
                      <v-radio
                        hide-details
                        :value="'text-decoration:none!important;'"
                        label="Nenhuma"
                        class="ml-4"
                      />
                    </v-radio-group>
                  </v-card-actions>
                </RowLabel>

                <!-- ESPAÇAMENTO ENTRE AS LETRAS DO BOTÃO -->
                <RowLabel label="Espaçamento entre letras" class="my-3" medium>
                  <v-slider
                    v-model="button.letter_spacing"
                    max="10"
                    min="-2"
                    track-color="lightSign"
                    thumb-color="primary"
                    thumb-label
                    ticks
                    hide-details
                    color="primary"
                  />
                </RowLabel>

                <!-- SOMBRA DO BOTÃO -->
                <RowLabel label="Sombra do botão" class="mb-3" medium>
                  <v-slider
                    v-model="button.shadow_box"
                    max="10"
                    min="0"
                    track-color="lightSign"
                    thumb-color="primary"
                    thumb-label
                    ticks
                    hide-details
                    color="primary"
                  />
                </RowLabel>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <v-footer class="border-top">
        <v-row class="mx-0 my-2 pa-0">
          <v-col
            :order="$vuetify.breakpoint.xs ? 2 : 1"
            class="ma-0 pa-0 d-flex justify-start"
          >
            <v-btn
              color="secondary lighten-3"
              elevation="0"
              text
              large
              :block="$vuetify.breakpoint.xs"
              class="font-weight-medium text-none text-body-1 px-1"
              @click="showHide = false"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col
            :order="$vuetify.breakpoint.xs ? 1 : 2"
            class="ma-0 pa-0 d-flex justify-end"
          >
            <v-btn
              :disabled="!linkIsValid"
              color="primary"
              elevation="0"
              large
              :block="$vuetify.breakpoint.xs"
              class="font-weight-medium text-none text-body-1"
              @click="addButton"
            >
              <v-icon v-text="'mdi-plus-box'" left />
              Adicionar botão
            </v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </v-card>
  </v-dialog>
</template>
<script>
import { allIcons } from "@/helpers/variables/icons";
import {
  customButtonDefault,
  buttonFormats,
  buttonSize,
  letterFormats,
  iconColors,
  borderStyles,
  buttonStyles,
  buttonIconAlignments,
  textDecorations,
} from "@/helpers/variables/editorOptionsDefault";
import {
  fluxStringToTemplate,
  fluxTemplateToPreview,
} from "@/helpers/services/tagPreview";

import RowLabel from "@/components/general/RowLabel";
import IconPicker from "@/components/forms/field_pickers/IconPicker";
import LinkInput from "@/components/forms/inputs/LinkInput";
import BorderPicker from "@/components/forms/field_pickers/BorderPicker";
import { changeDriveURL } from "@/helpers/services/images";
import SelectColor from "@/components/sign/SelectColor";

export default {
  name: "AddCustomButtomDialog",

  props: {
    show: { type: Boolean, default: false },
    width: { type: [Number, String], default: 700 },
  },

  components: {
    RowLabel,
    SelectColor,
    IconPicker,
    LinkInput,
    BorderPicker,
  },

  data() {
    return {
      button: Object.assign({}, customButtonDefault),
      customButtonDefault,
      buttonFormats,
      buttonSize,
      letterFormats,
      iconColors,
      borderStyles,
      buttonStyles,
      buttonIconAlignments,
      textDecorations,
      selectedIcon: null,
      currentTab: 0,
      tabs: [
        {
          name: "Geral",
          icon: "mdi-wrench-cog-outline",
        },
        {
          name: "Bordas",
          icon: "mdi-border-style",
        },
        {
          name: "Ícones",
          icon: "mdi-emoticon-excited-outline",
        },
        {
          name: "Cores",
          icon: "mdi-format-color-fill",
        },
        {
          name: "Avançado",
          icon: "mdi-tune-variant",
        },
      ],
      linkIsValid: false,
    };
  },

  computed: {
    hexColorButtonIcon() {
      const { icon_color } = this.button;
      if (icon_color === "light_grey") {
        return "#9B9B9B";
      } else if (icon_color === "white") {
        return "#FFFFFF";
      }
      return "#000000";
    },

    customIcon: {
      get() {
        return this.button.custom_icon_url;
      },
      set(url) {
        this.button.custom_icon_url = changeDriveURL(url);
      },
    },

    /**
     * Getter to load the correct url for left icon
     */
    previewIconUrlUrl() {
      if (this.selectedIcon) {
        const icon = allIcons.find((item) => item.key === this.selectedIcon);

        if (icon) {
          return icon.url[this.button.icon_color];
        }
        return "";
      }
      return null;
    },

    /**
     * Getter to load the info of current icon at left
     */
    mainIconInfo() {
      if (this.selectedIcon) {
        const icon = allIcons.find((item) => item.key === this.selectedIcon);

        if (icon) {
          return icon.key;
        }
        return "";
      }
      return null;
    },

    /**
     * Getter to load the correct url for right icon
     */
    previewArrowIconUrl() {
      if (this.button.right_arrow) {
        const icon = allIcons.find(
          (item) => item.key === this.button.right_arrow
        );

        if (icon) {
          return icon.url[this.button.icon_color];
        }
        return null;
      }
      return null;
    },

    /**
     * Getter to load the info of current icon at right
     */
    rightIconInfo() {
      if (this.button.right_arrow) {
        const icon = allIcons.find(
          (item) => item.key === this.button.right_arrow
        );

        if (icon) {
          return icon.key;
        }
        return "";
      }
      return null;
    },

    /**
     * Getter to display/hide current dialog
     */
    showHide: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    /**
     * Getter to load box shaddow of button
     */
    buttonShadow() {
      const shadow = parseInt(this.button.shadow_box);

      return shadow > 0
        ? `box-shadow: ${shadow - 2}px ${shadow}px ${shadow * 2}px #616161;`
        : `box-shadow: 0px 0px 0px;`;
    },

    /**
     * Button padding according with the button size
     */
    buttonPaddingBottomTop() {
      if (this.button.size === "small") {
        return 5;
      } else if (this.button.size === "medium") {
        return 10;
      } else {
        return 15;
      }
    },

    /**
     * Getter to set icon size plus extra width
     */
    iconSize() {
      return this.lineHeight + this.buttonPaddingBottomTop;
    },

    /**
     * Getter set correct padding
     */
    buttonPaddings() {
      if (this.button.size === "small") {
        return `padding: ${this.buttonPaddingBottomTop}px 15px;`;
      } else if (this.button.size === "medium") {
        return `padding: ${this.buttonPaddingBottomTop}px 20px;`;
      } else {
        return `padding: ${this.buttonPaddingBottomTop}px 28px;`;
      }
    },

    /**
     * Text line height
     */
    lineHeight() {
      const { font_size } = this.button;
      return parseInt(font_size);
    },

    /**
     * Button background
     */
    buttonBackground() {
      const { button_style, background_color } = this.button;
      if (button_style === "filled") {
        return `background:${background_color}!important;`;
      } else {
        return `background:transparent!important;`;
      }
    },

    buttonBorders() {
      const { border_size, border_style, border_color, borders } = this.button;

      const fullBordered = borders.includes("bordered");

      const hideBorder = borders.length === 0;

      const borderLeft = `border-left: ${border_size}px ${border_style} ${border_color}!important`;
      const borderRight = `border-right: ${border_size}px ${border_style} ${border_color}!important`;
      const borderTop = `border-top: ${border_size}px ${border_style} ${border_color}!important`;
      const borderBottom = `border-bottom: ${border_size}px ${border_style} ${border_color}!important`;

      if (hideBorder) {
        return `border:0px!important`;
      } else if (fullBordered) {
        return `border:${border_size}px ${border_style} ${border_color}!important`;
      } else {
        return borders
          .reduce((acc, item) => {
            if (item == "border-left") {
              acc.push(borderLeft);
            } else if (item === "border-right") {
              acc.push(borderRight);
            } else if (item === "border-top") {
              acc.push(borderTop);
            } else if (item === "border-bottom") {
              acc.push(borderBottom);
            }
            return acc;
          }, [])
          .join(";");
      }
    },

    /**
     * Getter to load the styles of the button
     */
    buttonStyle() {
      const { format, font_size, letter_transform, text_color } = this.button;

      return `${this.buttonBackground}color:${text_color}!important;
              border-radius:${format};font-size:${font_size}px;${this.buttonShadow}
              color:${text_color}!important;text-transform:${letter_transform};
              ${this.buttonBorders};${this.letterSpacingHtml}
              display:inline-flex!important;align-items: center!important;`;
    },

    /**
     * Getter to load the correct button html
     */
    buttonHtml() {
      const { link, text_color, icon_align, text_decoration } = this.button;

      return `<button style="${this.buttonStyle};">
                <a
                  href="${link}"
                  target="_blank"
                  style="
                    ${text_decoration}
                    color:${text_color}!important;
                    display:inline-flex!important;
                    align-items: center!important;
                    ${this.buttonPaddings}
                ">
                  ${icon_align == "left" ? this.mainIcon : ""}
                  <b>${this.buttonTextHtml}</b>
                  ${icon_align == "right" ? this.mainIcon : ""}
                  ${this.previewArrowIconUrl ? this.rightIcon : ""}
                </a>
              </button>
          &nbsp;
        `;
    },

    /**
     * Getter to load the custom html of the left icon
     */
    mainIcon() {
      const leftIcon = this.button.icon_align === "left";
      const margin = leftIcon ? `margin:0px 15px 0 0;` : `margin:0px 0 0 15px;`;

      if (this.customIcon) {
        return `
          <img
            src="${this.customIcon}"
            width="${this.iconSize}"
            alt="custom-icon"
            style="${this.customIcon ? margin : ""}"
          />`;
      } else if (this.previewIconUrlUrl) {
        return `
          <img
            src="${this.previewIconUrlUrl}"
            width="${this.iconSize}"
            alt="${this.mainIconInfo}"
            style="${this.previewIconUrlUrl ? margin : ""}"
          />`;
      } else {
        return "";
      }
    },

    /**
     * Getter to load the custom html of the right icon
     */
    rightIcon() {
      const hasRightIcon = this.button.icon_align === "right" && this.mainIcon;
      const padding = hasRightIcon
        ? `padding: 0px 0px 0px 5px;`
        : `padding: 0px 0px 0px 10px;`;
      return `
        <img
            src="${this.previewArrowIconUrl}"
            height="${this.iconSize}"
            alt="${this.rightIconInfo}"
            style="${this.previewArrowIconUrl ? padding : ""}"
          />
      `;
    },

    /**
     * Getter of the current text
     */
    buttonTextHtml() {
      const { text } = this.button;

      return text
        ? `<span>
            ${text}
          </span>`
        : "";
    },

    letterSpacingHtml() {
      const { letter_spacing } = this.button;
      return letter_spacing !== 0 ? `letter-spacing:${letter_spacing}px;` : "";
    },

    /**
     * Getter of preview with link instead of tagss
     */
    previewButtonHtml() {
      return fluxTemplateToPreview(fluxStringToTemplate(this.buttonHtml));
    },
  },

  watch: {
    "button.size"() {
      if (this.button.size === "x-small") {
        this.button.font_size = 13;
      } else if (this.button.size === "small") {
        this.button.font_size = 14;
      } else if (this.button.size === "medium") {
        this.button.font_size = 18;
      } else if (this.button.size === "large") {
        this.button.font_size = 24;
      } else {
        this.button.font_size = 30;
      }
    },

    "button.border_size"() {
      if (this.button.border_size > 0 && this.button.borders.length === 0) {
        this.button.borders = ["bordered"];
      } else if (this.button.border_size === 0) {
        this.button.borders = [];
        this.button.border_style = "solid";
        this.button.border_color = "#000000";

        if (this.button.button_style === "outline") {
          this.button.button_style = "link";
        }
      }
    },

    "button.button_style"() {
      const { button_style } = this.button;
      if (button_style === "outline") {
        this.button.borders = ["bordered"];
        this.button.border_style = "solid";
        this.button.border_color = "#000000";
        this.button.border_size = 2;
      } else {
        this.button.borders = [];
        this.button.border_style = "solid";
        this.button.border_color = "#000000";
        this.button.border_size = 0;
      }
    },

    "button.borders"() {
      if (this.button.borders.length > 0 && this.button.border_size === 0) {
        this.button.border_size = 2;
      } else if (this.button.borders.length === 0) {
        this.button.border_size = 0;
      }
    },

    "button.text_decoration"() {
      if (!this.button.text_decoration) {
        this.button.text_decoration = "text-decoration:none!important;";
      }
    },

    customIcon() {
      if (this.customIcon) {
        this.selectedIcon = null;
      }
    },

    showHide() {
      if (this.showHide) {
        this.button = Object.assign({}, customButtonDefault);
        this.currentTab = 0;
        this.selectedIcon = null;
        this.customIcon = null;
      }
    },
  },

  methods: {
    addButton() {
      this.$emit("add-button", this.buttonHtml);
      this.showHide = false;
    },

    invertBackgroundAndTextColor() {
      const aux = this.button.background_color;
      this.button.background_color = this.button.text_color;
      this.button.text_color = aux;
    },
  },
};
</script>
